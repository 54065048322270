// Change layout variables (with "!default" flag)
// Note: please only adjust Bootstrap variables, or make BS-compatible additions
//$enable-rounded: false !default;

$primary-faded : lighten($primary, 53%);

$border-width: 2px;
$border-radius-lg: 12px;
$theme-colors: (
        'primary': $primary,
        'primary-faded': $primary-faded,
        'secondary': $secondary,
        'tertiary':    $tertiary,
        'danger':    $danger,
        'success':    $success,
        'info':    $info,
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1900px
);

$border-radius: 0.5rem;
$border-radius-sm: 0.45rem;

$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-lg: $border-radius-lg;

$font-family-base: 'Poppins', sans-serif;
$headings-color: $primary;

@import '@/src/scss/main.scss';

form#CustomerForm-Login {
  small.text-muted a {
    color: $text-muted;
  }
}

.border-info {
  border: 2px solid $info;
  border-radius: 12px;
}

footer {
  a {
    color: $white;
  }
}

div.photo-banner {
  position: relative;
  top: 0;
  left: 0;

  #bottom-layer {
    position: relative;
    top: 0;
    left: 0;
    max-height: 650px;
    min-height: 250px;
    width: 100%;
  }

  #top-layer {
    position: absolute;
    bottom: -2px;
    left: 0;
    max-height: 150px;
    width: 100%;
  }
}

div.social-icons {
  margin-left: 10px;
  display: inline-block;

  i {
    &:hover {
      color: $primary !important;
    }
  }
}


.top-menu {
  color: $dark;
  font-weight: 400;
  font-size: 16px;

  ul {
    margin-bottom: 0;

    li {
      margin: 10px 10px 10px 10px;
      list-style: none;

      a {
        color: $dark;
        text-decoration: none;

      }

      .active {
        a {
          color: $info;
        }
      }
    }
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: $white;
  @include media-breakpoint-down(lg) {
    font-size: 0.82rem;
  }
}

.nav-cart {
  @extend .position-relative;
  .nav-link {
    @extend .px-lg-3;
    &.disabled {
      .badge {
        display: none;
      }
    }
  }
  .badge {
    @extend .ml-2, .ml-lg-0;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 8px;
      right: 0;
    }
  }
}
