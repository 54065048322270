// import font
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

//variables
$primary: #009fe3;
$secondary: #F39200;
$tertiary: #9D9D9C;
$danger: #E6332A;
$success: #95C11F;
$info: #951B81;

$hamburger-active-layer-color: white;

@import "extended-utilities";
@import '@/src/scss/preset/sportinaalsmeer.scss';
//@import 'mainMenu';

$owl-image-path: '~owl.carousel/src/img/';
@import "~owl.carousel/src/scss/owl.carousel";

.flex-wrapper {
  display: flex;
  min-height: 70vh;
  flex-direction: column;
  justify-content: space-between
}

.min-h-100 {
  min-height: 100% !important;
}

//.wrapper {
//  margin-bottom: -12rem;
//}

.content-wrapper {
  padding: 1.5rem 2rem 2rem 2rem;
  @include media-breakpoint-down(md) {
    padding: 1.5rem 1rem 1rem 1rem;
  }
}

.main-headerless {
  @extend .pt-5;
  @extend .mt-5;
}

.border-radius-top-1 {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.border-radius-bottom-1 {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.content-news-detail-wrapper {
  img {
    width: 100%;
    height: auto;
  }
}

article {
  border-radius: 15px;
  .border-rad-bottom {
    border: 1px solid $dark;
    border-radius: 0 0 15px 15px;
  }

  .border-rad-top {
    border-radius: 15px 15px 0 0;
  }
}

.mobile-hamburger-color {
  @extend .text-white;
  @extend .my-3;
  @extend .pb-1;
}

.navbar {
  padding: 0 8px;
  li.nav-item {
    margin-bottom: 6px;
    a {
      padding: 16px 0px 10px 0px;
    }
    &.active:after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 6px solid $primary;
      margin-top: -6px;
      position: relative;
      bottom: -6px;
      opacity: 1;
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }
    &:not(.active):hover:after{
      content: '';
      display: block;
      width: 100%;
      border-bottom: 6px solid $primary;
      margin-top: -6px;
      position: relative;
      bottom: -6px;
      opacity: 1;
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }
  }
}

.bg-header-bar {
  background: $tertiary;
}

.share-kop-wrapper p{
  font-weight: 600;
}

figure.img-cover {
  width: 100%;
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(md) {
    height: 170px;
  }
  @include media-breakpoint-down(sm) {
    height: 130px;
  }
}

th {
  background: none !important;
  border-top: none !important;
  border-bottom: 2px solid $primary !important;
}

.rounded-img {
  border: none;
  border-radius: 1.25rem;
  margin-bottom: .5rem;
}

.hover--box-shadow {
  text-decoration: none !important;
  img {
    transition: all 0.3s;
  }
  &:hover {
    img {
      box-shadow: 5px 5px 15px 5px #0000000f;
    }
  }
}

.dropdown-menu {
  .nav-link.dropdown-item {
    color: #212529 !important;
  }

  .dropdown-toggle {
    color: #212529 !important;
  }
}

.dropdown-menu.sub-dropdown.show {
  background: #e1e1e1;
}

.menu-block {
  border: 2px solid $white;
  border-radius: 15px;
  align-content: center;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  transition: all 0.3s;

  &:hover {
    background-color: $dark;
  }

  &.active {
    background-color: $dark;
  }

  a {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $white;
    width: 100px;
    height: 100px;

    i.fas {
      display: inline-block;
      width: 100%;
    }

    &:hover {
      text-decoration: none;
    }

  }
}
.space-logo {
  @include media-breakpoint-up(md){
    margin-top: 7rem;
  }
}

@include media-breakpoint-up(md) {
  .position-md-static {
    position: static !important;
  }
}

//.planner-table {
//  @include media-breakpoint-down(md) {
//    th:last-child, td:last-child {
//      display: none !important;
//    }
//  }
//}

.word-break {
  word-break: break-word;
}

.navbar-brand {
  display: block;
  position: absolute;
  left: 130px;
  top: 0;
  @include media-breakpoint-down(sm) {
    right: 0;
    margin: 0;
  }
  img {
    height: 10rem;
    position: absolute;
    top: -3px;
    background: white;
    border-radius: .25rem;
    padding: 8px;
    z-index: 999;
    max-width: 250px;
    max-height: 125px;
    box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.14);
    @include media-breakpoint-down(xl) {
      height: 6rem;
      left: -1rem;
    }
    @include media-breakpoint-down(lg) {
      left: -7rem;
    }
    @include media-breakpoint-down(md) {
      top: -2px;
      z-index: 99;
      left: 55vw;
    }
    @include media-breakpoint-down(sm) {
      top: 2px;
      height: 4rem;
      right: 0;
      left: auto;
      margin: 0;
    }
  }
}

.decenter-right {
  @include media-breakpoint-up(xxxl) {
    margin-right: 8rem !important;
    width: 50%;
  }
}

.home h2#rooster-header {
  text-align: center;
}

#form-container {
  margin-left: inherit !important;
  margin-right: inherit !important;
}

.home {
  form#rooster-form div.form-group {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  #form-container {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  h2#rooster-header {
    color: $info;
    text-align: center;
  }
}

footer {
  .social-icons {
    margin: 0;
  }
}

#invoices div.card-header {
  background-color: $info;
  color: $white;
  border-radius: 0;
}

#invoices div.card-footer {
  background-color: $white;
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  border-radius: 0;
}


#invoices div.card-header span.js-resend-invoice {
  color: $white !important;
}

@include media-breakpoint-up(sm) {
  #side-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px;
    z-index: -1;
  }

  #side-menu-bg {
    height: 100vh;
    background-color: $info;
  }

  .navbar {
    z-index: 2;
  }

  #topNav {
    z-index: 2;
    background-color: $white;
  }
}

#topNav li.active a{
  color: $info;
}

#footer-img {
  z-index: -2;
  position: relative;
}

.navbar-nav .dropdown-menu {
  background-color: $light;
  border-radius: 0px;
  top: 60px;
  padding: 0;
  border: none;
  a {
    padding: 16px 8px !important;
    color: $dark;
    &:hover {
      background-color: $primary;
    }
  }
}

.yolo {
  color: pink;
}

.footer-cta-banners {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
  .footer-cta-item:hover {
    filter: contrast(0.5);
  }
}

.quick-menu-item {
  word-break: break-all;
  &:hover {
    filter: contrast(.5);
  }
}

.dropdown-menu {
  min-width: 100%;
  width: auto !important;
  left: auto;
}

.sidebar-nav {
  h1,h2,h3,h4,h5,h6 {
    color: $white;
  }
  color: $white;
}

.btn-login {
  @extend .btn-dark;
  //background-color: $dark;
}

div#Rooster {
  padding-top: 0rem !important;
}

footer a:hover {
  color: $white;
}

.sidebar {
  min-width: 500px;
  max-width: 500px;
  background: $primary;
  transition: all .3s;
  position: relative;
  z-index: 1;
  min-height: 90vh;
  @include media-breakpoint-down(md) {
    min-width: 100%;
    min-height: unset;
  }
  .sidebar-nav {
    padding: 0 6rem;
    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }
  }
}

// Calendar
$space: 1.5rem;

.calendar {
  margin: auto (-$space);
  box-shadow: 0 ($space * .125) ($space * .25) rgba(0, 0, 0, .025);


  .owl-carousel:not(.owl-loaded) {
    //padding: 0 1.5rem;
    > .item:not(:first-child) {
      display: none;
    }
  }

  .owl-carousel .item {
    position: relative;
    z-index: 100;
    -webkit-backface-visibility: hidden;
  }

  li figure {
    padding-top: 0; height: $space * 10;
    border-radius: 12px;
    overflow: hidden;
    background-size: cover;
    .inner {
      position: relative;
      text-align: center; text-shadow: 0 0.063em 0.063em*2 rgba(0,0,0,.5); color: $white;
      h5, .h5 { font-size: 1.5rem; color: white; }
    }
    .inner-wrapper {
      display: flex; align-items: center; justify-content: center;
      z-index: 1;
      height: 100%;
      background: rgba(0,0,0,.5);
      border-radius: 12px;
      overflow: hidden;
    }

    .inner,
    .inner-wrapper { @include transition(); }
  }

  .extrainfo {
    padding: $space * .5;
    a { line-height: $space; }
  }

  li figure:hover {
    .inner-wrapper {
      opacity: 0;
    }
  }
}

.small {
  font-size: 0.8rem;
}

.owl-nav {
  position: absolute;
  top: -2.25rem;
  right: 9px;
  left: 9px;
  font-size: .875rem;
  text-align: right;
  @extend .px-3;
}

.owl-carousel {
  padding: 0 9px 0 9px;
}

.calendar-item {
  border: solid 2px $primary;
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 12px;
}

.double-spaced {
  height: 58px;
}

#bottom-layer {
  object-fit: cover;
}

.footer-overlap {
  // TODO: Rename in all installations to theme-spacer, since here this name doesnt make sense.
  padding-top: 2rem;
}

.border-thick {
  border: solid 2px;
}

.search-btn {
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-top: 1rem;
  }
}

.card-body {
  .media-body {
    form {
      display: inline !important;
      button {
        padding-top: 0;
        padding-bottom: 0;
        height: 30px;
        margin-top: .5rem;
      }
    }
  }
}


.include-stretch-hover {
  transition: color 0.2s, fill 0.2s;
  &:hover{
    color: $primary !important;
    fill: $primary !important;
    .btn {
      background-color: $white;
      color: $dark;
    }
    small {
      color: $primary;
    }
  }
}

.card-stretch-hover {
  transition: color 0.2s, fill 0.2s;
  &:hover{
    small {
      color: $primary !important;
    }
  }
}

.intrest-badge {
  height: 4.2rem;
  width: 4.2rem;
  white-space: pre-wrap;
  right: 1rem;
  top: -2rem;
  p {
    vertical-align: middle;
    font-size: 0.6rem;
  }
}

.sidebar-badge-list {
  .intrest-badge {
    height: 6.2rem;
    width: 6.2rem;
  }
}

.content-sidebar {
  @include media-breakpoint-up(lg) {
    position: relative;
    right: -2rem;
    height: 100%;
  }
}

.sidebar-badge-list {
  @include media-breakpoint-up(lg) {
    margin-left: -3rem;
  }
}

.reset-position {
  top: inherit !important;
  bottom: inherit !important;
  left: inherit !important;
  right: inherit !important;
}
.sidebar-bg-container-breaker {
  position: absolute;
  top: 0;
  left: 0;
  right: -1500px;
  bottom: 0;
  z-index: -1;
  @include media-breakpoint-down(lg) {
    left: -2rem;
  }
}

.h-400 {
  height: 400px;
}

.full-round-banner {
  border-radius: 50rem;
  border: solid 4px;
  &::after{
    content: '';
    height: 2.3rem;
    width: 2.3rem;
    background: inherit;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 50rem;
    transition: all 0.5s;
  }
  &:hover{
    &::after{
      bottom: 1rem;
      right: -0.5rem;
    }
  }
  img {
    border-radius: 50rem;
  }
}

.footer--flavor-logo {
  width: 160px;
  position: absolute;
  top: -50px;
  right: 11rem;
}

.force-text-danger {
  h1, h2, h3, h4, h5, h6, a:not(.btn) {
    color: $danger !important;
  }
}

.force-text-info{
  h1, h2, h3, h4, h5, h6, a:not(.btn) {
    color: $info !important;
  }
}

.force-text-success {
  h1, h2, h3, h4, h5, h6, a:not(.btn) {
    color: $success !important;
  }
}
